import React, {useState} from 'react'
import Layout from '../components/layout'
import Seo from "../components/seo"
import InnovationJSON from "../content/innovations.json"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import KeyFeaturesFullWidth from '../components/rippleGoKeyFeaturesFullWidth'
import KeyFeaturesMobileWidth from '../components/rippleGoKeyFeaturesMobileWidth'
import "../styles/bootstrap.scss"
import "animate.css/animate.min.css";

const DEFAULT_WINDOW_SIZE = 1500
const breakPoint = 1400

export default function RippleGo() {
    const content = InnovationJSON.innovations[3]
    const [windowWidth, setWindowWidth] = useState(DEFAULT_WINDOW_SIZE)

    React.useEffect(() => {
        if(typeof window === 'undefined' || !window.document) return

        window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
    }, [])
    
    return (
        <Layout>
            <Seo title={content.title}/>
            <img className="parallax ripplego" src="/river-map.jpg" alt="Parallax"/>
            <div className="banner-cover home" />
            <div className="banner animate__animated fadeInDownSmall">
                <div>
                    <img src="/ripplego_logo_white.png" alt="RippleGo" style={{width: '80%', minWidth: '300px', margin: '1rem 0 2rem'}}/>
                    <h6 style={{color: "white", marginBottom: "2rem", marginTop: '0rem', fontStyle: 'italic'}}>{content.slogan}</h6>
                </div>
            </div>

            
            <Container fluid className="bg-white" style={{padding: '3% 0 0', boxShadow: 'inset 0 5px 5px #bebebe'}}>
                <Container className="my-0 pb-5 pt-4">
                    <Row style={{whiteSpace: "pre-wrap", justifyContent: "space-between"}}>
                        <Col lg={4} className="d-flex justify-content-center mb-4">
                            <img style={{height: '32.5vh', objectFit: 'cover', marginLeft: '-15px'}} src="/ripplego-sub.jpg" alt="Barge" />
                        </Col>
                        <Col lg={7} className="my-auto">
                            <h5>{content.introSlogan}</h5>
                            <br/>
                            <p><span style={{fontStyle: 'italic'}}><b>RippleGo</b></span> is a new software application that
                                uses predictive analytics to provide accurate
                                and timely alerts on river level conditions,
                                navigation hazards, lock status changes and
                                bridge air gap clearance.
                            </p>
                            <p>Each time something changes, <span style={{fontStyle: 'italic'}}><b>RippleGo </b></span>
                                automatically alerts you and recalculates your
                                ETA to destination, so you can get the most
                                out of every voyage.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="px-0 text-white ripplego-bg" style={{backgroundColor: '#009090'}}>
                <Container fluid className="my-0 text-center container-padding">
                    <h5>Why Did We Create RippleGo?</h5>
                    {content.solution.map( (info, index) => (
                        <div key={index} className="text-left mb-5">
                            <h5 className={"pb-2 " + (info.header === "Our Solution" ? 'mt-5' : 'mt-2')}>{info.header}</h5>
                            {info.subheader && 
                                <h6 className="mb-3">{info.subheader}</h6>
                            }
                            {info.header === "Our Solution" ? 
                            <p>RippleGo is a <u><a style={{color: 'white'}} href='https://patents.google.com/patent/US20220245456A1/' target='_blank' rel="noopener noreferrer">patented</a></u>, AI based voyage planning tool and alert system that uses predictive analytics to monitor and analyze dozens of data sources (including AIS data from Spire) to forecast changes and provide accurate, real-time notifications on current conditions and forecasted changes along your voyage (including changes in river level conditions, navigation hazards, lock status changes and bridge air gap clearances).</p>
                            :
                                <p>{info.description}</p>
                            }
                        </div>
                    ))}
                    <Link 
                        className="link-button ripplego mx-auto mt-4"
                        to="/contact"
                    >
                        Request a Demo
                    </Link>
                    <br/>
                    <br/>
                </Container>
            </Container>

            <Container fluid className="px-0" style={{backgroundColor: 'white', position: "relative"}}>
                <Container fluid className="my-0 container-padding">
                    <h5 className="pb-2 mt-2" >{content.videoHeader}</h5>
                    <div className="embed-responsive embed-responsive-16by9 w-100 mx-auto" style={{borderRadius: 10}}>
                        <iframe 
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/ByC9MjPHsIs" 
                            title="Trabus Technologies RippleGo Video" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </Container>
            </Container>

          
            <Container fluid className="px-0 text-white ripplego-bg" style={{backgroundColor: '#009090', position: "relative"}}>
                <div className="wave style2">
                    <svg className="style2" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill style2"></path>
                    </svg>
                </div>    
                <Container fluid className="my-0" style={{padding: '6% 15% 10%'}}>
                    <h5 className="pb-2 mt-4" >{content.demoVideoHeader}</h5>
                    <div className="embed-responsive embed-responsive-16by9 w-100 mx-auto" style={{borderRadius: 10}}>
                        <iframe 
                            width="1904" 
                            height="775" 
                            src="https://www.youtube.com/embed/ihvlHUVBmwM" 
                            title="Trabus Technologies RippleGo Demo Video" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </Container>
            </Container>

            <Container fluid id="box3" className="px-0 position-relative key-features" style={{backgroundColor: 'white', position: "relative"}}>                       
                <div className="wave style3">
                    <svg className="style3" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill style3"></path>
                    </svg>
                </div>   
                <Container fluid className="my-0 text-center container-padding" style={{paddingBottom: '9rem'}}>
                    <h5 className="pb-5 pt-4 text-dark" >
                        KEY FEATURES
                        <div className="divider"></div>
                    </h5>
                    {(windowWidth && windowWidth < breakPoint) ? <KeyFeaturesMobileWidth windowWidth={windowWidth} /> : <KeyFeaturesFullWidth />}
                </Container>
            </Container>

            <Container fluid id="box4" className="px-0 position-relative text-white ripplego-bg" style={{backgroundColor: '#009090'}}>
                <div className="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <Container fluid className="my-0 text-center container-padding">
                    <h5 className="pb-3 mt-5 pt-3" >{content.tester.header}</h5>
                    <div className="mx-auto mb-4 bg-white" style={{width: '80px', height: '3px'}}/>
                    <p className='mb-5' style={{whiteSpace: "pre-wrap"}}>{content.tester.info}</p>
                    <Link 
                        className="link-button ripplego mx-auto mt-4"
                        style={{width: '250px'}}
                        to="/contact"
                    >
                        Become a Beta Tester
                    </Link>
                    <br/>
                    <br/>
                    <small>Already have an account? <Link className='text-white' to="https://app.ripplego.com/"><u>Log in</u>.</Link></small>
                    <br/>
                </Container>
            </Container>
        </Layout>
    )
}
